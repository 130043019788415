.project-card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem; /* Adjust gap as needed */
}

.project-card {
    flex: 1 1 300px; /* Adjust the basis as needed */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; /* Ensures the card takes full height */
}

.project-card img {
    max-height: 220px;
    width: 100%;
    object-fit: cover; /* Ensures the image covers the area while maintaining aspect ratio */
    object-position: top center; /* Centers the image horizontally and aligns it to the top */
    display: block; /* Removes any bottom space caused by inline images */
}
